<template>
  <tr>
    <th
      :style="size ? headerStyle : ''"
      scope="row"
    >
      <slot name="title">
        {{ title }}
      </slot>
    </th>
    <td :style="size ? rowStyle : ''">
      <slot></slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'text-row',
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    headerStyle () {
      if (!this.size) return false
      const size = 100 - Number(this.size)
      return `width: ${size}%`
    },
    rowStyle () {
      if (!this.size) return false
      return `width: ${this.size}%`
    },
  },
}
</script>
